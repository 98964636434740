<script setup lang="ts">
import {
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  defineProps,
  computed,
} from 'vue';
import FeatureHighlighterTooltip from './FeatureHighlighterTooltip.vue';
import { FeatureHighlight } from '../store/feature-highlight';

interface ActiveTooltip extends FeatureHighlight {
  element: HTMLElement;
  timeoutId?: number;
}

const props = defineProps<{
  features: FeatureHighlight[];
}>();

const dismissedFeatures = ref<number[]>([]);

const activeTooltips = ref<ActiveTooltip[]>([]);
const observedElements = new WeakMap<Element, ActiveTooltip>();
let observer: MutationObserver | null = null;

const TOOLTIP_DELAY = 1500;

const currentFeatures = computed(() => {
  return props.features.filter(
    (feature) => !dismissedFeatures.value.includes(feature.id)
  );
});

const checkForElements = () => {
  for (const featureHighlight of currentFeatures.value) {
    const element = document.querySelector(featureHighlight.selector);
    if (element && !observedElements.has(element)) {
      // Set a timeout to delay the tooltip display
      const timeoutId = window.setTimeout(() => {
        // Ensure the element still exists and hasn't been dismissed
        if (document.body.contains(element)) {
          const activeTooltip: ActiveTooltip = {
            ...featureHighlight,
            element: element as HTMLElement,
          };
          activeTooltips.value.push(activeTooltip);
          observedElements.set(element, activeTooltip);
        }
      }, TOOLTIP_DELAY);

      // Store the timeout ID with the element
      const activeTooltip: ActiveTooltip = {
        ...featureHighlight,
        element: element as HTMLElement,
        timeoutId,
      };
      observedElements.set(element, activeTooltip);
    }
  }

  // Clean up tooltips for elements that no longer exist
  activeTooltips.value = activeTooltips.value.filter((tooltip) => {
    const elementExists = document.body.contains(tooltip.element);
    if (!elementExists) {
      if (tooltip.timeoutId) {
        clearTimeout(tooltip.timeoutId);
      }
      observedElements.delete(tooltip.element);
    }
    return elementExists;
  });
};

const dismissFeature = (id: number) => {
  dismissedFeatures.value.push(id);

  localStorage.setItem(
    'dismissedFeatures',
    JSON.stringify(dismissedFeatures.value)
  );

  activeTooltips.value = activeTooltips.value.filter((tooltip) => {
    if (tooltip.id === id) {
      if (tooltip.timeoutId) {
        clearTimeout(tooltip.timeoutId);
      }
      observedElements.delete(tooltip.element);
      return false;
    }
    return true;
  });
};

onMounted(() => {
  const localStorageDismissedFeatures =
    localStorage.getItem('dismissedFeatures') || null;
  dismissedFeatures.value = localStorageDismissedFeatures
    ? JSON.parse(localStorageDismissedFeatures)
    : [];

  observer = new MutationObserver(checkForElements);
  observer.observe(document.body, { childList: true, subtree: true });
  checkForElements();
});

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
  // Clear all pending timeouts
  activeTooltips.value.forEach((tooltip) => {
    if (tooltip.timeoutId) {
      clearTimeout(tooltip.timeoutId);
    }
  });
});

watch(
  () => currentFeatures.value,
  () => {
    checkForElements();
  }
);
</script>

<template>
  <div>
    <FeatureHighlighterTooltip
      v-for="(tooltip, index) in activeTooltips"
      :key="tooltip.id"
      :element="tooltip.element"
      :title="tooltip.title"
      :readMoreLink="tooltip.read_more_link"
      @dismiss="dismissFeature(tooltip.id)"
    />
  </div>
</template>
