<template>
  <div class="form-group" :class="getStyle(value)">
    <label class="form-label" for="number">
      {{ field.label }}
      <sup class="text-danger" v-if="field.is_required">*</sup>
    </label>
    <div class="input-group">
      <span class="input-group-text" v-if="field.options.prefix">
        {{ field.options.prefix }}
      </span>
      <input
        type="number"
        :disabled="disabled"
        step="any"
        class="form-control"
        v-model="value"
        :class="{
          'border-danger': field.is_required && (!value || value == ''),
        }"
        name="number"
        ref="numberInput"
        @blur="handleBlur"
      />
      <span class="input-group-text" v-if="field.options.unit">
        {{ field.options.unit }}
      </span>
      <span
        v-if="hasTolerance"
        class="input-group-text"
        :class="{
          'bg-success': passesTolerance,
          'bg-warning text-light': !passesTolerance,
        }"
      >
        <i
          class="fas fa-fw"
          :class="{
            'fa-check-circle': passesTolerance,
            'fa-times-circle': !passesTolerance,
          }"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { checkIsInputValueEmpty } from '../../business-logic/input-value';
import { fieldHasTolerance, inputValuePasses } from '../../utils/tolerance';
import Hazard from '../classes/Hazard.js';
import { safeMultiply } from '../../utils/unit-conversion';

export default {
  props: {
    field: Object,
    inputValue: Object,
    sectionIndex: Number,
    isSafety: {
      required: false,
      default: () => false,
    },
    lastValue: {
      type: Object,
      required: false,
    },
    inputValues: Array,
  },
  inject: ['formContext'],
  data: () => ({
    isDefaultInputValue: false,
    isWaiting: false,
  }),
  computed: {
    disabled() {
      return (
        this.field.options.is_readonly || this.inputValue?.options?.disabled
      );
    },
    value: {
      get() {
        if (
          (!this.inputValue ||
            this.inputValue.value === undefined ||
            this.inputValue.value === null) &&
          (this.field.options?.default_value || this.field.options?.increment)
        ) {
          let defaultValue;
          if (this.lastValue?.value || this.lastValue?.value === 0) {
            defaultValue =
              Math.round(
                (parseFloat(this.lastValue.value) +
                  parseFloat(this.field.options?.increment || 0)) *
                  1000000
              ) / 1000000;
            this.isDefaultInputValue = true;
            this.setValue(defaultValue);
            return defaultValue;
          }

          defaultValue =
            Math.round(
              parseFloat(this.field.options?.default_value || 0) * 1000000
            ) / 1000000;
          this.isDefaultInputValue = true;
          this.setValue(defaultValue);
          return defaultValue;
        }

        return safeMultiply(this.inputValue.value, 1);
      },
      set(value) {
        if (!this.isWaiting) {
          this.isWaiting = true;
        }
        this.setValueDebounced(value);
      },
    },
    hasTolerance() {
      return fieldHasTolerance(this.field);
    },
    passesTolerance() {
      if (!this.hasTolerance) {
        return false;
      }
      return inputValuePasses(this.field, this.inputValue, this.inputValues);
    },
  },
  methods: {
    setValue(value) {
      const _isDefaultInputValue = this.isDefaultInputValue;
      if (_isDefaultInputValue) {
        this.isDefaultInputValue = false;
      }
      this.$root.$emit('updateInputValue', {
        inputValue: { ...this.inputValue, value },
        field: this.inputValue.template_field_id,
        sectionIndex: this.inputValue.template_section_index,
        templateTabId: this.inputValue.template_tab_id,
        isDefaultInputValue: _isDefaultInputValue,
      });
    },
    getStyle(value) {
      if (!value || !this.inputValue?.options?.is_health_safety) return null;
      const hazard = new Hazard({});
      return hazard.calculateStyling(value);
    },
    handleBlur(e) {
      if (checkIsInputValueEmpty(this.inputValue, this.field)) {
        e.target.value = '';
      }
    },
  },
  created() {
    this.setValueDebounced = _debounce((value) => {
      this.setValue(value);
      if (this.isWaiting) {
        this.isWaiting = false;
      }
    }, 1000);
  },
  watch: {
    isWaiting(newValue) {
      this.formContext.setIsBusy(newValue);
    },
  },
  mounted() {
    if (this.field.options?.increment) {
      this.$refs.numberInput.step = this.field.options.increment;
    }
  },
};
</script>
