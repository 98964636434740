<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DialogOptions } from '../store/dialog';
import Modal from './Modal.vue';
import InputTextFloating from './InputTextFloating.vue';
import { useToastStore } from '../store/toasts';

const props = defineProps<{
  title: string;
  message?: string;
  danger: boolean;
  options: DialogOptions;
  validationString?: string;
}>();
const toastStore = useToastStore();
const emit = defineEmits(['confirm', 'cancel']);

const acceptText = ref('');

const canAccept = computed(() => {
  return acceptText.value?.toLowerCase() === props.validationString?.toLowerCase();
});

const handleConfirm = () => {
  if (canAccept.value || !props.validationString) {
    emit('confirm');
  } else {
    toastStore.error('Invalid confirmation string');
  }
};
</script>

<template>
  <Modal @close="$emit('cancel')" class="dialog-modal">
    <template #header v-if="title">
      {{ title }}
    </template>
    <div v-if="message">
      {{ message }}
    </div>

    <InputTextFloating
      class="mt-2"
      v-if="validationString"
      v-model="acceptText"
      name="acceptance"
      :label="`Type '${validationString}' to confirm`"
    />
    <template #footer>
      <button
        class="btn w-100 mb-2"
        :class="danger ? 'btn-danger' : 'btn-primary'"
        @click="handleConfirm"
      >
        {{ options?.confirmButtonText || 'Confirm' }}
      </button>
      <button class="btn btn-flat w-100" @click="$emit('cancel')">
        {{ options?.cancelButtonText || 'Cancel' }}
      </button>
    </template>
  </Modal>
</template>
