import api from '../api/index.js';

import { getFilters } from '@/js/helpers/data-filter.mjs';
import { getDefaultSampleFields } from '../helpers/table-fields.ts';
import { getDefaultChemicalFields } from '../helpers/chemical-utils.ts';
import { getUniqueValuesFromArray } from '@component-library/utils';

const resetState = ({ commit }) => {
  commit('RESET_STATE');
};

const loadPaginatedSamples = async (
  { commit, state, dispatch },
  { data, maxPageNumber }
) => {
  try {
    commit('LOAD_PAGINATED_SAMPLES_PENDING');

    const response = await api.loadPaginatedSamples(data);
    const samples = response.data.samples;

    const flattenedSamples = _getFlattenedPaginatedData(
      maxPageNumber,
      state.load_paginated_samples_complete?.number_of_items,
      data.page,
      state.load_all_paginated_samples,
      samples.data
    );

    commit('LOAD_PAGINATED_SAMPLES_COMPLETE', {
      samples,
      flattened_samples: flattenedSamples,
      number_of_items: response.data.number_of_items,
    });

    if (samples.data.length > 0) {
      dispatch('loadGatherValues');
    }
  } catch (e) {
    commit('LOAD_ALL_DATA_ERROR', e.response);
    throw e;
  }
};

const loadChemicals = ({ commit, state }, { data, maxPageNumber }) => {
  commit('LOAD_CHEMICALS_PENDING');

  return api
    .loadChemicals(data)
    .then((response) => {
      const chemicals = response.data.chemicals;

      const flattenedChemicals = _getFlattenedPaginatedData(
        maxPageNumber,
        state.load_paginated_chemicals_complete?.number_of_items,
        data.page,
        state.load_chemicals_complete,
        chemicals.data
      );

      commit('LOAD_CHEMICALS_COMPLETE', {
        chemicals,
        flattened_chemicals: flattenedChemicals,
        number_of_items: response.data.number_of_items,
      });
    })
    .catch((e) => {
      commit('LOAD_ALL_DATA_ERROR', e.response);

      throw e;
    });
};

const _getFlattenedPaginatedData = (
  maxPageNumber,
  numberOfItems,
  currentPage,
  allItems,
  paginatedItems
) => {
  let flattened = [];

  for (let page = 1; page < maxPageNumber + 1; page++) {
    for (let i = 0; i < page * 24; i++) {
      if (!numberOfItems || i < numberOfItems) {
        flattened[i] = allItems[i] || {
          id: null,
        };
      }
    }
  }

  for (let i = 0; i < paginatedItems.length; i++) {
    if (paginatedItems[i] || null) {
      flattened[(currentPage - 1) * 24 + i] = paginatedItems[i];
    }
  }

  return flattened;
};

const loadResults = ({ commit }, { data }) => {
  commit('LOAD_RESULTS_PENDING');

  return api
    .loadResults(data)
    .then((response) => {
      commit('LOAD_RESULTS_COMPLETE', response.data.results);
    })
    .catch((e) => {
      throw e;
    });
};

const loadGatherValues = async ({ commit, state }) => {
  const fieldIds = state.sample_fields
    .filter((f) => f.template_field_id)
    .map((f) => f.template_field_id);

  const depthIds = state.sample_fields
    .filter((f) => f.depth_field_id)
    .map((f) => f.depth_field_id);

  const uniqueFieldIds = getUniqueValuesFromArray([...fieldIds, ...depthIds]);
  if (uniqueFieldIds.length === 0) {
    return;
  }

  const sample_ids = state.load_paginated_samples_complete?.data.map(
    (s) => s.id
  );

  const gatherResponse = await api.loadGatherValues({
    field_ids: uniqueFieldIds,
    sample_ids,
  });

  commit('LOAD_GATHER_VALUES_COMPLETE', gatherResponse.data);
};

const resetAllData = (context) => {
  context.commit('RESET_ALL_TABLE_DATA');
};

const resetChemicalData = (context) => {
  context.commit('RESET_ALL_CHEMICALS_DATA');
};

const resetSampleData = (context) => {
  context.commit('RESET_ALL_SAMPLES_DATA');
};

const resetResultData = (context) => {
  context.commit('RESET_RESULTS_DATA');
};

const resetExportData = (context) => {
  context.commit('RESET_EXPORT_DATA');
};

const setLoadedResults = (context) => {
  context.commit('SET_LOADED_RESULTS');
};

const setTableHasError = (context) => {
  context.commit('LOAD_ALL_DATA_ERROR');
};

const loadAdditionalData = (context, callback) => {
  context.commit('LOAD_ADDITIONAL_DATA_PENDING');

  api
    .loadAdditionalData()
    .then(({ data }) => {
      context.commit(
        'SET_SAMPLE_FIELDS',
        data.sample_fields === null
          ? getDefaultSampleFields()
          : data.sample_fields
      );

      context.commit(
        'SET_CHEMICAL_FIELDS',
        data.chemical_fields === null
          ? getDefaultChemicalFields()
          : data.chemical_fields
      );

      context.commit('LOAD_ADDITIONAL_DATA_COMPLETE', data);

      callback();
    })
    .catch((e) => {
      context.commit('LOAD_ALL_DATA_ERROR', e.response);
      throw e;
    });
};

const setTableSettingsField = (context, data) => {
  context.commit('SET_TABLE_SETTINGS_FIELD', data);
};

const updateTableFields = async (context, data) => {
  context.commit(
    data.key === 'sample_fields' ? 'SET_SAMPLE_FIELDS' : 'SET_CHEMICAL_FIELDS',
    data.fields
  );

  try {
    await api.modifyTableFields(data);
  } catch (e) {
    throw e;
  }
};

const configureFilters = ({ commit, rootState, rootGetters }, filters) => {
  const newFilters = getFilters(
    filters || {},
    rootState.project,
    rootGetters['dashboard/get_prerequisite_field']('has_enviro_data'),
    rootGetters['dashboard/get_scenario_group']?.type === 1
  );

  // Chemical groups should persist on clear
  if (filters === null) {
    newFilters.groups =
      rootState['data-table'].filters?.groups || newFilters.groups;
    newFilters.show_other_group =
      rootState['data-table'].filters?.show_other_group ||
      newFilters.show_other_group;
  }

  commit('CONFIGURE_FILTERS', newFilters);
};

const modifyChemicalGroup = (context, group) => {
  context.commit('MODIFY_CHEMICAL_GROUP', group);
};

const deleteChemicalGroup = (context, id) => {
  context.commit('DELETE_CHEMICAL_GROUP', id);
};

const getChemicalUnits = (context, data) => {
  context.commit('LOAD_CHEMICAL_UNITS_PENDING');

  api
    .getAllChemicalUnits(data)
    .then((response) => {
      context.commit('LOAD_CHEMICAL_UNITS_COMPLETE', response.data);
    })
    .catch((error) => {
      context.commit('LOAD_CHEMICAL_UNITS_ERROR', error);
    });
};

const setEditingData = (context, data) => {
  context.commit('SET_EDITING_DATA', data);
};

const setTableOnlyView = (context, tableOnlyView) => {
  context.commit('SET_TABLE_ONLY_VIEW', tableOnlyView);
};

const modifySample = (context, sample) => {
  context.commit('MODIFY_SAMPLE', sample);
};

const deleteSample = (context, id) => {
  context.commit('DELETE_SAMPLE', id);
};

const absorbSample = (context, data) => {
  context.commit('ABSORB_SAMPLE', data);
};

const modifyResult = (context, result) => {
  context.commit('MODIFY_RESULT', result);
};

const deleteResult = (context, id) => {
  context.commit('DELETE_RESULT', id);
};

const modifyResultNote = (context, data) => {
  context.commit('MODIFY_RESULT_NOTE', data);
};

const deleteResultNote = (context, id) => {
  context.commit('DELETE_RESULT_NOTE', id);
};

const modifyChemical = (context, chemical) => {
  context.commit('MODIFY_CHEMICAL', chemical);
};

const deleteChemical = (context, id) => {
  context.commit('DELETE_CHEMICAL', id);
};

const modifyScenarioSet = (context, set) => {
  context.commit('MODIFY_SCENARIO_SET', set);
};

const setHydrocarbonExport = (context, exportType) => {
  context.commit('SET_HYDROCARBON_EXPORT', exportType);
};

const setTableTemplate = (context, template) => {
  context.commit('SET_TABLE_TEMPLATE', template);
};

const updateGuideline = (context, data) => {
  context.commit('UPDATE_GUIDELINE', data);
};

const createSampleWithResults = (context, sample) => {
  context.commit('CREATE_SAMPLE_WITH_RESULTS', sample);
};

const updateApplyFiltersStatus = (context, status) => {
  context.commit('UPDATE_APPLY_FILTER_STATUS', status);
};

const toggleSampleRPD = (context, sample) => {
  context.commit('TOGGLE_SAMPLE_RPD', sample);
};

const updateChemicalRPDAcceptance = ({ commit }, data) => {
  commit('UPDATE_CHEMICAL_RPD_ACCEPTANCE', data);
};

const loadExportSettings = async ({ state, commit, getters }) => {
  commit('SET_IS_LOADING_EXPORT_SETTINGS', true);

  const { data } = await api.getExportSettings(getters.get_shared_properties);

  commit('SET_IS_EXPORT_PREVIEW');
  commit('SET_EXPORT_SETTINGS', { ...data, isLastChunk: true });
  commit('SET_IS_LOADING_EXPORT_SETTINGS', false);
};

const updateChemicalOrdering = async (context) => {
  const scenarioGroup = context.rootGetters['dashboard/get_scenario_group'];

  context.dispatch(
    'dashboard/updateScenarioGroup',
    {
      is_updating_ordering: true,
    },
    {
      root: true,
    }
  );

  try {
    await api.updateChemicalOrdering({
      group_id: scenarioGroup.id,
      ...context.getters.get_shared_properties,
    });
  } catch (e) {
    throw e;
  }
};

const updateLayerFilters = async (context) => {
  const scenarioGroup = context.rootGetters['dashboard/get_scenario_group'];
  context.dispatch(
    'dashboard/updateScenarioGroup',
    {
      is_updating_layer_filters: true,
    },
    {
      root: true,
    }
  );
  try {
    await api.updateLayerFilters({
      id: scenarioGroup.id,
    });
  } catch (e) {
    throw e;
  }
};

const updateStatistics = async (context) => {
  const scenarioGroup = context.rootGetters['dashboard/get_scenario_group'];
  if (!scenarioGroup.has_statistics) {
    return;
  }

  context.dispatch(
    'dashboard/updateScenarioGroup',
    {
      is_updating_statistics: true,
    },
    {
      root: true,
    }
  );

  try {
    await api.updateStatistics({
      group_id: scenarioGroup.id,
      ...context.getters.get_shared_properties,
    });
  } catch (e) {
    throw e;
  }
};

export default {
  resetState,

  loadPaginatedSamples,
  loadChemicals,
  loadResults,
  loadGatherValues,
  resetAllData,

  resetChemicalData,
  resetSampleData,
  resetResultData,
  resetExportData,
  setLoadedResults,
  setTableHasError,

  loadAdditionalData,
  setTableSettingsField,
  updateTableFields,

  configureFilters,

  modifyChemicalGroup,
  deleteChemicalGroup,

  getChemicalUnits,

  setEditingData,
  setTableOnlyView,

  modifySample,
  deleteSample,
  absorbSample,
  modifyResult,
  deleteResult,
  modifyResultNote,
  deleteResultNote,
  modifyChemical,
  deleteChemical,
  modifyScenarioSet,

  setHydrocarbonExport,
  setTableTemplate,

  updateGuideline,
  createSampleWithResults,
  updateApplyFiltersStatus,

  toggleSampleRPD,
  updateChemicalRPDAcceptance,
  loadExportSettings,
  updateChemicalOrdering,
  updateStatistics,
  updateLayerFilters,
};
