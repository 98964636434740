import {
  formatElevation,
  formatSampleDepth,
  formatSampleType,
} from '@/js/helpers/sample';

export type Field = {
  key?: string;
  template_field_id?: number;
  is_repeatable?: boolean;
  title: string;
  is_default?: boolean;
  has_calculation?: boolean;
  is_header?: boolean;

  has_filter?: boolean;
  filter_key?: string; //handle legacy filters with new filter system
  parseFilterDisplayTitle?: Function;
  parseFilterValue?: Function;
};

export const allFields: Field[] = [
  {
    key: 'sampled_depth',
    title: 'Depth',
    is_default: true,
    has_filter: true,
    filter_key: 'depths',
    parseFilterDisplayTitle: (option) => {
      return formatSampleDepth(option);
    },
    parseFilterValue: (option) => {
      return {
        start_depth: option.start_depth,
        end_depth: option.end_depth,
      };
    },
  },
  {
    key: 'sampled_date',
    title: 'Sampled Date',
    is_default: true,
    has_filter: true,
    filter_key: 'date_range',
  },
  {
    key: 'lab_title',
    title: 'Lab Title',
  },
  {
    key: 'matrix',
    title: 'Matrix',
  },
  {
    key: 'original_title',
    title: 'Original Title',
  },
  {
    key: 'location_code',
    title: 'Location Code',
  },
  {
    key: 'lab_report_number',
    title: 'Lab Report No.',
    has_filter: true,
    filter_key: 'lab_report_numbers',
  },
  {
    key: 'soil_description',
    title: 'Soil Description',
    has_filter: true,
    filter_key: 'soil_descriptions',
  },
  {
    key: 'lab_sample_type',
    title: 'QC Sample Type',
    has_filter: true,
    parseFilterDisplayTitle: (option) => {
      return formatSampleType(option.value);
    },
  },
  {
    key: 'latitude',
    title: 'Latitude',
  },
  {
    key: 'longitude',
    title: 'Longitude',
  },
  {
    key: 'guideline_soil_options',
    title: 'Soil Type / Depth',
  },
  {
    key: 'gw_guideline_soil_options',
    title: 'GW Soil Type / Depth',
  },
  {
    key: 'composite_of',
    title: 'No. of sub samples in composite',
  },
  {
    key: 'field_ph_test',
    title: 'ASS - field pH Test',
    has_calculation: true,
  },
  {
    key: 'sampled_elevation',
    title: 'Elevation',
    has_filter: true,
    filter_key: 'elevations',
    parseFilterDisplayTitle: (option) => {
      return formatElevation(option.start_elevation, option.end_elevation);
    },
    parseFilterValue: (option) => {
      return {
        start_elevation: option.start_elevation,
        end_elevation: option.end_elevation,
      };
    },
  },
  {
    key: 'apec',
    title: 'APEC',
    has_filter: true,
    filter_key: 'apecs',
  },
  {
    key: 'precinct',
    title: 'Precinct',
    has_filter: true,
  },
  {
    key: 'catchment',
    title: 'Catchment',
    has_filter: true,
  },
  {
    key: 'data_source',
    title: 'Data Source',
    has_filter: true,
  },
  {
    key: 'document_index',
    title: 'Document Index',
    has_filter: true,
  },
  {
    key: 'location_code_alt',
    title: 'Location Code Alt',
  },
  {
    key: 'analysis',
    title: 'Analysis',
    has_filter: true,
    filter_key: 'analysis',
  },
  {
    key: 'raw_matrix',
    title: 'Raw Matrix',
  },
  {
    key: 'parent_sample',
    title: 'Parent Sample',
  },
  {
    key: 'batch',
    title: 'Batch',
    has_filter: true,
  },
];

export const getDefaultSampleFields = () => {
  return allFields.filter((field) => field.is_default);
};
