import { getScenarioRequestParameters } from '@/js/helpers/scenario.mjs';
import { getFilters, getDefaultFilters } from '@/js/helpers/data-filter.mjs';
import { getStyleByScenario } from '@/js/helpers/scenario.js';
import { isEditingCell } from '../helpers/context-menu';
import { getHydrocarbonHierarchy } from '../helpers/guideline-export.js';
import { CHEMICAL_FIELDS, ChemicalFieldKey } from '../helpers/chemical-utils';
import {
  AcceptanceMatrixRow,
  getDefaultAcceptanceMatrix,
} from '../types/acceptance-matrix';
import { convertMatrixStringToType } from '@/js/helpers/matrix';
import useTableDesign from '../composables/useTableDesign';
import { EditableBlockType } from '../components/table/editable-footer/types/block-editor';
import { ProjectScenarioGroupType } from '@component-library/enviro';
import { deepEqual, omit } from '@component-library/utils';

const get_paginated_chemicals_current_page = (state) =>
  state.load_paginated_chemicals_complete?.current_page || 0;

const loading_infinite_scroll = (state, getters) => {
  return getters.get_orientation == 'horizontal'
    ? state.load_paginated_samples_pending
    : state.load_chemicals_pending;
};

const get_chemical_groups = (state) => {
  if (
    !state.filters ||
    !state.filters.groups ||
    state.filters.groups.length == 0
  ) {
    return [];
  }

  let groups = state.filters.groups
    .map((group) => {
      const chemGroup = state.all_chemical_groups.find((g) => g.id == group.id);

      if (!chemGroup) {
        return;
      }

      return {
        ...chemGroup,
        units: group.units,
      };
    })
    .filter((g) => g);

  if (state.filters.show_other_group) {
    groups = [
      ...groups,
      {
        group_title: 'Other',
        is_other: true,
      },
    ];
  }

  return groups
    .map((g) => {
      return {
        ...g,
        uuid: Math.floor(Math.random() * Date.now()).toString(),
      };
    })
    .filter((s) => !s.rendered);
};

const get_is_cell_highlighted = (state) => (id) => {
  return isEditingCell(id, state.editing_data);
};

const get_result_by_ids = (state) => (sample_id, chemical_id) => {
  return state.load_results_complete.find(
    (r) => r.sample_id == sample_id && r.chemical_id == chemical_id
  );
};

const get_style_by_scenario = (state) => (scenario) => {
  return getStyleByScenario(state.scenario_set, scenario);
};

const get_filters = (state, getters, rootState, rootGetters) => {
  return getFilters(
    state.filters,
    rootState.project,
    rootGetters['dashboard/get_prerequisite_field']('has_enviro_data'),
    rootGetters['dashboard/get_scenario_group']?.type === 1
  );
};

const has_changed_filters = (state, getters, rootState, rootGetters) => {
  const project = rootState.project;
  const has_enviro_data =
    rootGetters['dashboard/get_prerequisite_field']('has_enviro_data');
  const scenario_group = rootGetters['dashboard/get_scenario_group'];

  let filters = state.filters;

  filters = getFilters(
    filters,
    project,
    has_enviro_data,
    scenario_group?.type === 1
  );

  const defaultFilters = getDefaultFilters(
    project,
    has_enviro_data,
    scenario_group?.type === 1
  );

  return !deepEqual(
    omit(filters, ['groups', 'show_other_group']),
    omit(defaultFilters, ['groups', 'show_other_group'])
  );
};

const get_shared_properties = (state, getters, rootState, rootGetters) => {
  const scenarioGroup = rootGetters['dashboard/get_scenario_group'];
  return {
    matrix: rootState.matrix,
    filters: getters.get_filters,
    ...getScenarioRequestParameters(
      rootGetters['dashboard/get_processed_scenarios'],
      rootState.dashboard.processed_settings &&
        rootState.dashboard.processed_settings.by_documents
    ),
    orientation: getters.get_orientation,
    verification_type: getters.get_table_settings.verificationType,
    profile_id: scenarioGroup ? scenarioGroup.chemical_profile_id : null,
    use_grouped_location_codes: false,
  };
};

const get_orientation = (state) => {
  return state.scenario_set?.is_horizontal ? 'horizontal' : 'vertical';
};

const get_export_settings = (state) => {
  const isExporting = state.is_exporting;

  const hydrocarbonHierarchy = isExporting
    ? getHydrocarbonHierarchy(state.export_settings['hydrocarbon_hierarchy'])
    : null;

  const hydrocarbonHierarchyColSpan = hydrocarbonHierarchy
    ? Object.values(hydrocarbonHierarchy).reduce(
        (total, { colSpan }) => total + colSpan,
        0
      )
    : null;

  return {
    isExporting,
    isExportPreview: state.is_export_preview,
    isHydrocarbonExport: state.is_hydrocarbon_export,
    hydrocarbonExportType: state.hydrocarbon_export_type,
    hydrocarbonHierarchy: hydrocarbonHierarchy,
    hydrocarbonHierarchyColSpan,
    compositeSampleCalculations: isExporting
      ? state.export_settings['composite_sample_calculations']
      : [],
    hideHeadings: state.export_settings['hideHeadings'] || false,
    isLastChunk: state.export_settings['isLastChunk'] || false,
  };
};

const get_table_settings = (state, getters, rootState, rootGetters) => {
  const project = rootState.project;
  const verificationType =
    rootGetters['dashboard/get_scenario_group']?.type ===
    ProjectScenarioGroupType.RPD
      ? 'duplicate'
      : null;
  const useAcceptanceMatrix = project.use_rpd_acceptance_matrix == true;
  const tableDesign = useTableDesign(state.table_template.class);

  const chemicalFields = CHEMICAL_FIELDS.filter(
    (f) => state.chemical_fields.findIndex((ff) => ff.key === f.key) !== -1
  )
    .filter((f) => {
      switch (f.key) {
        case ChemicalFieldKey.EQL_FIELD:
          return state.table_settings.has_eql_column;
        case ChemicalFieldKey.RPD_FIELD:
          return verificationType && !useAcceptanceMatrix;
      }
      return true;
    })
    .map((f) => {
      f.title = tableDesign.getChemicalFieldTitle(f.key) || f.title;
      return f;
    });

  const getAcceptanceMatrix = (): Array<AcceptanceMatrixRow> => {
    const numberMatrix = convertMatrixStringToType(rootState.matrix);

    const acceptanceMatrix: Array<AcceptanceMatrixRow> =
      project.rpd_acceptance_matrix
        ? project.rpd_acceptance_matrix.filter((s) => s.matrix == numberMatrix)
        : [];

    return acceptanceMatrix.length > 0
      ? acceptanceMatrix
      : getDefaultAcceptanceMatrix(numberMatrix);
  };

  return {
    hasEQLColumn: state.has_eql_column,
    editingData: state.editing_data,
    tableOnlyView: state.table_only_view,
    togglableSampleFields: state.sample_fields,
    verificationType,
    scenarioSet: state.scenario_set,
    useAcceptanceMatrix,
    acceptanceMatrix: getAcceptanceMatrix(),
    chemicalFields,
  };
};

const has_reference_identifiers = (state) => {
  const footerSettings = state.scenario_set?.footer_settings;
  return footerSettings
    ? footerSettings[EditableBlockType.ADOPTED_CRITERIA].is_visible
    : true;
};

const table_filter_statistics = (state) => {
  return {
    total_samples: state.total_number_of_samples,
    visible_number_samples:
      state.load_paginated_samples_complete?.number_of_items || 0,
    total_chemicals: state.total_number_of_chemicals,
    visible_number_chemicals:
      state.load_paginated_chemicals_complete?.number_of_items || 0,
  };
};

export default {
  get_paginated_chemicals_current_page,

  loading_infinite_scroll,

  get_chemical_groups,

  get_is_cell_highlighted,

  get_result_by_ids,

  get_style_by_scenario,

  get_filters,
  has_changed_filters,

  get_shared_properties,
  get_orientation,

  get_export_settings,
  get_table_settings,

  has_reference_identifiers,
  table_filter_statistics,
};
