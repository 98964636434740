import { findTemplateField } from '@component-library/gather';
import _uniq from 'lodash/uniq';
import * as bl from '../../../business-logic';
import * as serviceApi from '../../../service-api';
import * as helpers from '../helpers';

export function setMapBoundaryLayerId(context, mapBoundaryLayerId) {
  context.commit('setMapBoundaryLayerId', mapBoundaryLayerId);
}

export function setDatasources(context, datasources) {
  context.commit('setDatasources', datasources);
}

export function setInputs(context, inputs) {
  context.commit('setInputs', inputs);
}

export function setSelectedIndex(context, selectedIndex) {
  context.commit('setSelectedIndex', selectedIndex);
}

export function resetMyState(context) {
  context.commit('resetMyState');
}

const getDefaultInputProps = () => ({
  range: bl.analysis.heatmap.DEFAULT_RANGE,
  colors: bl.analysis.heatmap.getColors(bl.analysis.heatmap.DEFAULT_RANGE),
  elevationInterval: bl.analysis.heatmap.getElevationInterval(
    bl.analysis.heatmap.DEFAULT_RANGE
  ),
  error: null,
  interpolationAlgorithmId: bl.tool.heatmap.INTERPOLATION_ALGORITHM_INVDIST,
  variogramModel: 'spherical',
  cellSize: 1,
});

export const analyzeSamplesInBoundary = helpers.withLoadingForAction(
  async (context, { boundary, extent, size, matrix, depthUnit }) => {
    const { allSamples } = context.rootState.maps;
    const samplesInBoundary = bl.sample.getSamplesInBoundary(
      allSamples,
      boundary
    );
    const datasources = [];
    const inputs = [];

    if (samplesInBoundary.length) {
      // Prepare inputs for Enviro samples.
      const chemicals = await serviceApi.sample.loadChemicals(matrix);
      if (chemicals.length) {
        const chemicalIds = chemicals.map((chemical) => chemical.chemical_id);
        const chemicalResults = await serviceApi.sample.loadChemicalResults(
          chemicalIds,
          matrix
        );
        if (chemicalResults.length) {
          datasources.push({
            depthUnit,
            chemicals,
            samples: samplesInBoundary,
            chemicalResults,
          });
          inputs.push({
            extent,
            size,
            matrix,
            sampleChemicalId: null,
            sampleIds: [],
            ...getDefaultInputProps(),
          });
        }
      }

      // Prepare inputs for Gather samples.
      const gatherSamples = bl.sample.getGatherSamples(samplesInBoundary);

      const templateTabIds = _uniq(
        gatherSamples.map((gs) => gs.template_tab_id)
      );

      const appendAppToDataSource = async (
        templateTabId,
        sampleIds,
        sampleLonLats = []
      ) => {
        let templateTab;
        try {
          templateTab = await serviceApi.gather.loadTemplateTab(templateTabId);
        } catch (e) {
          // The template could have been deleted;
          console.warn(`The app with id ${templateTabId} is not found.`);
          return;
        }

        if (
          !bl.analysis.heatmap.getEligibleTemplateSections(templateTab).length
        ) {
          return;
        }

        datasources.push({
          templateTab,
        });

        inputs.push({
          extent,
          size,
          sampleIds,
          sampleLonLats,
          templateSectionId: null,
          templateFieldId: null,
          ...getDefaultInputProps(),
        });
      };

      for (const appId of templateTabIds) {
        const sampleIds = bl.sample.extractSampleIds(
          gatherSamples.filter((gs) => gs.template_tab_id === appId)
        );

        await appendAppToDataSource(appId, sampleIds);
      }

      for (const appId of templateTabIds) {
        const { linked_apps } = await serviceApi.gather.loadHeatmapLinkedApps(
          bl.sample.extractSampleIds(gatherSamples).map((sampleId) => {
            return {
              template_tab_id: appId,
              sample_id: sampleId,
            };
          })
        );

        for (const appId of Object.keys(linked_apps).map((k) =>
          parseInt(k, 10)
        )) {
          const sampleIds = linked_apps[appId].map((la) => la.sample_id);
          const sampleLonLats = linked_apps[appId].map((la) => ({
            sample_id: la.sample_id,
            longitude: la.longitude,
            latitude: la.latitude,
          }));
          await appendAppToDataSource(appId, sampleIds, sampleLonLats);
        }
      }
    }

    await context.dispatch('setMapBoundaryLayerId', null);
    await context.dispatch('setDatasources', datasources);
    await context.dispatch('setInputs', inputs);
    await context.dispatch('setSelectedIndex', inputs.length ? 0 : -1);
  },
  helpers.MODULE_NAMES.HEATMAP,
  bl.analysis.heatmap.ACTION_TYPES.ANALYZE_SAMPLES
);

export const updateEnviroInput = helpers.withLoadingForAction(
  async (context, { index, key, value }) => {
    const { inputs } = context.state;
    const input = inputs[index];
    const { matrix, sampleChemicalId } = input;

    let nextInput = { ...input };
    if (key === 'sampleChemicalId') {
      nextInput = {
        ...nextInput,
        sampleChemicalId: value,
        sampleIds: [],
        ...getDefaultInputProps(),
      };
    } else if (key === 'sampleIds') {
      const range = await serviceApi.gather.getInputValueRange({
        matrix,
        sampleChemicalId,
        sampleIds: value,
      });
      const colors = bl.analysis.heatmap.getColors(range);
      const elevationInterval = bl.analysis.heatmap.getElevationInterval(range);
      nextInput = {
        ...nextInput,
        sampleIds: value,
        range,
        colors,
        elevationInterval,
        error: null,
      };
    } else if (
      [
        'colors',
        'elevationInterval',
        'error',
        'interpolationAlgorithmId',
        'variogramModel',
        'cellSize',
      ].includes(key)
    ) {
      nextInput = {
        ...nextInput,
        [key]: value,
      };
    }

    const nextInputs = [...inputs];
    nextInputs.splice(index, 1, nextInput);
    context.dispatch('setInputs', nextInputs);
  },
  helpers.MODULE_NAMES.HEATMAP,
  bl.analysis.heatmap.ACTION_TYPES.GENERIC
);

export const updateGatherInput = helpers.withLoadingForAction(
  async (context, { index, key, value }) => {
    const { datasources, inputs } = context.state;
    const datasource = datasources[index];
    const input = inputs[index];
    const {
      templateTab: { id: templateTabId },
    } = datasource;
    const { sampleIds, templateSectionId } = input;

    let nextInput = { ...input };
    if (key === 'templateSectionId') {
      nextInput = {
        ...nextInput,
        templateSectionId: value,
        templateFieldId: null,
        ...getDefaultInputProps(),
      };
    } else if (key === 'templateFieldId') {
      const range = await serviceApi.gather.getInputValueRange({
        templateTabId,
        templateSectionId,
        templateFieldId: value,
        sampleIds,
      });
      const colors = bl.analysis.heatmap.getColors(range);
      const elevationInterval = bl.analysis.heatmap.getElevationInterval(range);
      nextInput = {
        ...nextInput,
        templateFieldId: value,
        range,
        colors,
        elevationInterval,
        error: null,
      };
    } else if (
      [
        'colors',
        'elevationInterval',
        'error',
        'interpolationAlgorithmId',
        'variogramModel',
        'cellSize',
      ].includes(key)
    ) {
      nextInput = {
        ...nextInput,
        [key]: value,
      };
    }

    const nextInputs = [...inputs];
    nextInputs.splice(index, 1, nextInput);
    context.dispatch('setInputs', nextInputs);
  },
  helpers.MODULE_NAMES.HEATMAP,
  bl.analysis.heatmap.ACTION_TYPES.GENERIC
);

export const updateInput = async (context, { index, key, value }) => {
  const { datasources } = context.state;
  const datasource = datasources[index];
  const update = { index, key, value };
  if (context.getters.checkIsEnviroInput(datasource)) {
    await context.dispatch('updateEnviroInput', update);
  } else if (context.getters.checkIsGatherInput(datasource)) {
    await context.dispatch('updateGatherInput', update);
  }
};

export async function validateInput(context, index) {
  const { inputs } = context.state;
  const input = inputs[index];
  const { range, colors } = input;

  let error = null;
  while (true) {
    if (!range.count) {
      error = 'No values found for the samples.';
      break;
    }

    const numbers = colors.map((item) => parseFloat(item.label));
    if (numbers.find((item) => isNaN(item))) {
      error = 'Some numbers in the color palette are empty.';
      break;
    }
    const isAscending = numbers.every(
      (n, index) => index === 0 || n >= numbers[index - 1]
    );
    if (!isAscending) {
      error = 'Numbers in the color palette are not in ascending order.';
      break;
    }

    break;
  }

  await context.dispatch('updateInput', { index, key: 'error', value: error });
}

export const createHeatmapFiles = helpers.withLoadingForAction(
  async (context, { clipBoundaryFile, clipBoundaryId }) => {
    const { datasources, inputs, selectedIndex } = context.state;
    const selectedDatasource = datasources[selectedIndex];
    const selectedInput = inputs[selectedIndex];
    const type = bl.analysis.heatmap.getInputType(selectedDatasource);
    const {
      extent,
      size,
      sampleIds: sample_ids,
      sampleLonLats: sample_lonlats = [],
      colors,
      elevationInterval: elevation_interval,
      interpolationAlgorithmId: interpolation_algorithm_id,
      variogramModel: variogram_model,
      cellSize: cell_size,
    } = selectedInput;

    let data = {
      extent,
      size,
      sample_ids,
      sample_lonlats,
      colors,
      elevation_interval,
      interpolation_algorithm_id,
      variogram_model,
      cell_size,
      clipBoundaryFile,
      clipBoundaryId,
    };
    if (type === bl.analysis.heatmap.INPUT_TYPES.ENVIRO) {
      const { matrix, sampleChemicalId: sample_chemical_id } = selectedInput;
      data = {
        ...data,
        matrix,
        sample_chemical_id,
        // This value is used in the name of newly created heatmap and contours layers.
        chemical_title: selectedDatasource.chemicals.find(
          (item) => item.id === sample_chemical_id
        ).display_title,
      };
    } else {
      const {
        templateTab: { id: template_tab_id },
      } = selectedDatasource;
      const {
        templateSectionId: template_section_id,
        templateFieldId: template_field_id,
      } = selectedInput;
      data = {
        ...data,
        template_tab_id,
        template_section_id,
        template_field_id,
        // This value is used in the name of newly created heatmap and contours layers.
        template_field_label: findTemplateField(
          selectedDatasource.templateTab,
          template_section_id,
          template_field_id
        ).label,
      };
    }

    await serviceApi.geospatialAnalysis.createHeatmapFiles(type, data);
  },
  helpers.MODULE_NAMES.HEATMAP,
  bl.analysis.heatmap.ACTION_TYPES.CREATE
);

export const loadInterpolationAlgorithms = helpers.withLoadingForAction(
  async (context) => {
    const interpolationAlgorithms =
      await serviceApi.geospatialAnalysis.loadInterpolationAlgorithms();
    context.commit('setInterpolationAlgorithms', interpolationAlgorithms);
  },
  helpers.MODULE_NAMES.HEATMAP,
  bl.analysis.heatmap.ACTION_TYPES.GENERIC
);

export function setSelectedClipBoundaryId(context, value) {
  context.commit('setSelectedClipBoundaryId', value);
}
